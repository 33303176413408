import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import React, {useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@mui/material";
import {sendLogo} from '../../../app/slices/companiesSlice';
import _ from "lodash";
import {APP_URLS, companySectors, STORAGE} from "../../../app/config";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import axios from "axios";

function CompanySectorsSection({sectorsData: data, onUpdate}) {
    const {t} = useTranslation('CompanyCreateScreen', {keyPrefix: 'SectorsSection'});
    const [sectorsData, setSectorsData] = useState(data);
    const [currentEdit, setCurrentEdit] = useState(null);
    const [currentEmailEdit, setCurrentEmailEdit] = useState('');
    const [currentContactPersonEdit, setCurrentContactPersonEdit] = useState('');
    const [currentCommentEdit, setCurrentCommentEdit] = useState('');
    const [currentPhoneEdit, setCurrentPhoneEdit] = useState('');

    const handleEditRequest = (id, email, contactPerson, comment, phone) => {
        setCurrentEdit(id);
        setCurrentEmailEdit(email);
        setCurrentContactPersonEdit(contactPerson);
        setCurrentCommentEdit(comment);
        setCurrentPhoneEdit(phone);
    }

    const updateSector = (companyId, isConfirmed) => {
        const newArr = sectorsData.map((item) => {
            return companyId === item.id ? { ...item, isConfirmed } : item;
        });
        setSectorsData(newArr); 
    }
    
    const confirmSector = (companyId) => {
        const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }

        axios.put(`${APP_URLS.COMPANY_CONFIRM}/${companyId}`, {}, {headers}).then(result => {
            updateSector(companyId, result.data.isConfirmed);
        }).catch(e => {
            console.log(e)
        })
    }

    const handleUpdate = async () => {
        const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        };

        await axios.post(
            APP_URLS.COMPANY_CHANGE_EMAIL,
            {
                id: currentEdit,
                email: currentEmailEdit,
                contactPerson: currentContactPersonEdit,
                comment: currentCommentEdit,
                phone: currentPhoneEdit,
            },
            {headers}
        );

        setCurrentEdit(null);
        setCurrentEmailEdit('');
        setCurrentContactPersonEdit('');
        setCurrentCommentEdit('');
        setCurrentPhoneEdit('');

        await onUpdate();
    }

    return (
        <div className="fk-form-section">
            <h3 className="title">
                {t('title')}
            </h3>
            <Table className="fk-table">
                <TableHead className="fk-table-head">
                    <TableRow>
                        <TableCell>{t('sector')}</TableCell>
                        <TableCell>{t('contactPerson')}</TableCell>
                        <TableCell>{t('phone')}</TableCell>
                        <TableCell>{t('additionalEmail')}</TableCell>
                        <TableCell>{t('comment')}</TableCell>
                        <TableCell align="center">{t('confirmed')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sectorsData.map(({id, email, sectorId, contactPerson, phone, comment, isConfirmed, sectorName}, index) => {
                        return (
                            <TableRow>
                                <TableCell>
                                    {sectorName}
                                </TableCell>
                                <TableCell>
                                    {currentEdit === id
                                        ? <TextField
                                            fullWidth
                                            className="fk-input"
                                            value={currentContactPersonEdit}
                                            onChange={(event) => setCurrentContactPersonEdit(event.target.value)}
                                        />
                                        : contactPerson
                                    }
                                </TableCell>
                                <TableCell>
                                    {currentEdit === id
                                        ? <TextField
                                            fullWidth
                                            className="fk-input"
                                            value={currentPhoneEdit}
                                            onChange={(event) => setCurrentPhoneEdit(event.target.value)}
                                        />
                                        : phone
                                    }
                                </TableCell>
                                <TableCell>
                                    {currentEdit === id
                                        ? <TextField
                                            fullWidth
                                            className="fk-input"
                                            value={currentEmailEdit}
                                            onChange={(event) => setCurrentEmailEdit(event.target.value)}
                                        />
                                        : email
                                    }
                                </TableCell>
                                <TableCell>
                                    {currentEdit === id
                                        ? <TextField
                                            fullWidth
                                            className="fk-input"
                                            value={currentCommentEdit}
                                            onChange={(event) => setCurrentCommentEdit(event.target.value)}
                                        />
                                        : comment
                                    }
                                </TableCell>
                                <TableCell align="center">
                                        <button className="fk-edit-button"
                                                style={{backgroundColor: isConfirmed ? '#696969' : 'transparent', border: !isConfirmed ? "solid 2px #696969" : 'none', cursor: isConfirmed && 'default'}}
                                                onClick={() => confirmSector(id, index)}>
                                            <CheckIcon />
                                        </button>
                                </TableCell>
                                <TableCell>
                                    {id !== currentEdit
                                        ? <button
                                            className="fk-edit-button"
                                            onClick={() => handleEditRequest(id, email, contactPerson, comment, phone)}
                                        >
                                            <EditIcon/>
                                        </button>
                                        : <button
                                            className="fk-edit-button"
                                            onClick={() => handleUpdate()}
                                        >
                                            <SaveIcon/>
                                        </button>
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}

const mapStateToProps = (state) => ({
    sectorsData: state.companies.company.sectorsData,
});

const actions = {
    sendLogo,
};

export default connect(mapStateToProps, actions)(CompanySectorsSection);