import { format } from "date-fns";
import { userStatuses } from "../../../app/config/userStatuses";

export const infoFieldsArray = [
    {
        name: 'id',
    },
    {
        name: 'createdAt',
        convert: (value) => format(
                new Date(value ? value : new Date()),
                "yyyy-MM-dd 'hour:' HH:mm"
            )
    },
    {
        name: 'reportsAmount',
    },
    {
        name: 'isBlocked',
        items: userStatuses
    },
    {
        name: 'email',
        modelName: 'Email',
    },
    {
        name: 'firstname',
        modelName: 'Firstname',
    },
    {
        name: 'lastname',
        modelName: 'Lastname',
    }
];