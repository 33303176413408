import { IconButton } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { companySectors } from "../../../app/config";
import { fetchCompanies, setFilter } from "../../../app/slices/companiesSlice";
import { FilterMenu } from "../../filterMenu";
import { ScreenHeader } from "../../screenHeader";
import { ListAdd } from '../../../assets/icons';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showModal } from "../../../app/slices/modalSlice";
import { useState } from "react";
import ExportEmailsModal from "../../modal/exportEmailsModal";
import MergeCompaniesModal from "../../modal/mergeCompaniesModal";

function CompaniesTableFilter({ filter, selectedItems, csvLink }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('CompaniesScreen', { keyPrefix: 'TableFilter' });
  const { t: t_common } = useTranslation('Common');
  const { t: t_merge } = useTranslation('CompaniesScreen');
  const { t: t_button } = useTranslation('Buttons');
  let timer = null;

  const [showMergeModal, setShowMergeModal] = useState(false);
  const [showExportEmailsModal, setShowExportEmailsModal] = useState(false);
  const handleFilterChange = (field, value) => {
    clearTimeout(timer);

    dispatch(setFilter({ field, value, }));

    timer = setTimeout(() => {
      dispatch(fetchCompanies());
    }, 500);
  };

  const handleCreateClick = () => {
    navigate('/companies/add');
  }

  const handleMergeSelectedCompanies = () => {
    if (!selectedItems || selectedItems.length <= 1) {
      dispatch(showModal({
        title: t_merge('MergeCompanies.SelectErrorTitle'),
        message: t_merge('MergeCompanies.ToManyErrorMessage'),
        mode: 'danger',
      }));

      return;
    }

    if (selectedItems.length > 2) {
      dispatch(showModal({
        title: t_merge('MergeCompanies.ToManyErrorTitle'),
        message: t_merge('MergeCompanies.ToManyErrorMessage'),
        mode: 'danger',
      }));

      return;
    }

    setShowMergeModal(true);
  }

  const onMergeModalComplete = async (isSuccess) => {
    setShowMergeModal(false);

    if (isSuccess) {
      dispatch(showModal({
        title: t_merge('MergeCompanies.success'),
        mode: 'normal',
      }));

      await dispatch(fetchCompanies());
    } else {
      dispatch(showModal({
        title: t_merge('MergeCompanies.error'),
        mode: 'danger',
      }));
    }
  }

  const onExportEmailsPress = () => {
    setShowExportEmailsModal(true);
  }

  return (
    <>
      <ScreenHeader withSearch search={filter.search} onChange={(event) => handleFilterChange('search', event.target.value)} />
      <div className="fk-filter-container">
        <IconButton color="primary" aria-label="upload picture" component="button" onClick={handleCreateClick}>
          <img src={ListAdd} alt="button" /> {t_button('add')}
        </IconButton>
        <div className="fk-filter">
          <FilterMenu
            label={t('sector.text')}
            options={companySectors}
            selected={filter.sector}
            onChange={(option) => handleFilterChange('sector', option)}
          />
          <FilterMenu
            withValue
            label={t('openComplainsAmount.text')}
            options={[]}
            selected={filter.openComplainsAmountFrom}
            buttonText={`${t('openComplainsAmount.from')} ${filter.openComplainsAmountFrom ?? ''}`}
            onChange={(option) => handleFilterChange('openComplainsAmountFrom', option)}
          />
          <FilterMenu
            withValue
            options={[]}
            selected={filter.openComplainsAmountTo}
            buttonText={`${t('openComplainsAmount.to')} ${filter.openComplainsAmountTo ?? ''}`}
            onChange={(option) => handleFilterChange('openComplainsAmountTo', option)}
          />
          <FilterMenu
            withValue
            label={t('closedComplainsAmount.text')}
            options={[]}
            selected={filter.closeComplainsAmountFrom}
            buttonText={`${t('closedComplainsAmount.from')} ${filter.closeComplainsAmountFrom ?? ''}`}
            onChange={(option) => handleFilterChange('closeComplainsAmountFrom', option)}
          />
          <FilterMenu
            withValue
            options={[]}
            selected={filter.closeComplainsAmountTo}
            buttonText={`${t('closedComplainsAmount.to')} ${filter.closeComplainsAmountTo ?? ''}`}
            onChange={(option) => handleFilterChange('closeComplainsAmountTo', option)}
          />
          <FilterMenu
              actionButton
              buttonText={t('mergeSelectedCompanies')}
              onClick={handleMergeSelectedCompanies}
          />
          <FilterMenu
              actionButton
              link={csvLink}
              buttonText={t_common('downloadCsv')}
          />
          <FilterMenu
              actionButton
              onClick={onExportEmailsPress}
              buttonText={'Export emails'}
          />
        </div>
      </div>
      <MergeCompaniesModal
        show={showMergeModal}
        companies={selectedItems}
        fallback={onMergeModalComplete}
        onCancel={() => setShowMergeModal(false)}
      />
      <ExportEmailsModal
          show={showExportEmailsModal}
          companies={selectedItems}
          fallback={onMergeModalComplete}
          onCancel={() => setShowExportEmailsModal(false)}
      />
    </>
  );
}

const mapStateToProps = state => ({
  filter: state.companies.filter,
  selectedItems: state.companies.selectedItems,
  csvLink: state.companies.csvLink,
});

export default connect(mapStateToProps, {})(CompaniesTableFilter);