import { connect } from "react-redux";
import React from "react";
import { TableHeader, TablePagination } from "../../table";
import { Table, TableBody, TableContainer } from "@mui/material";
import { useTranslation } from "react-i18next";
import { logsTableHead } from "../../../app/config/tables/logsTableHead";
import LogsTableRow from "../../table/rows/logsTableRow";

function ComplainLogsList({ logs, filter, onRequestSort, onPreviousPage, onNextPage, onDetails }) {
    const { t } = useTranslation('LogsScreen');

    return (
        <div className={`fk-company-complains-list${onDetails ? ' --on-details' : ''}`}>
            <div className="fk-filter-container">
                <h3>History of events related to the case</h3>
            </div>
            <TableContainer>
                <Table size="medium" className="fk-table">
                    <TableHeader
                        items={logsTableHead}
                        orderBy={filter.orderBy}
                        order={filter.orderDirection}
                        onRequestSort={onRequestSort}
                        rowCount={logs.length}
                        screen="LogsScreen"
                    />
                    <TableBody>
                    {logs.map((log) => (
                            <LogsTableRow
                                key={log.id}
                                log={log}
                            />
                        )
                    )}
                    </TableBody>
                </Table>
                {(!logs || logs.length === 0) &&
                    <p className="fk-no-items">
                        {t('noLogRecords')}
                    </p>
                }
            </TableContainer>
            <TablePagination
                filter={filter}
                handlePreviousPage={onPreviousPage}
                handleNextPage={onNextPage}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    logs: state.logs.data,
    filter: state.logs.filter,
});

const actions = {
};

export default connect(mapStateToProps, actions)(ComplainLogsList);