import {useEffect, useState} from "react";
import axios from "axios";
import {APP_URLS} from "../app/config";

const useSectors = () => {
    const [sectors, setSectors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchSectors = () => {
        axios.get(APP_URLS.SECTORS_LIST).then(result => {
            setSectors(result.data);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        fetchSectors();
    }, []);
    
    return {
        sectors,
        isLoading
    }
}

export default useSectors;