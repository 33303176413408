//#region imports

import {format} from 'date-fns';
import {useTranslation} from "react-i18next";
import {APP_URLS} from '../../app/config/urls';
// import Image from 'next/image';
// import { CONFIG } from '../../../config';
import {NoProfilePhoto} from '../../assets/images';
import ConsumerRankIcon from './consumerRankIcon';
import ComplaintMessageEditForm from "../forms/complain/ComplaintMessageEditForm/complaintMessageEditForm";
import {useEffect, useState} from "react";
import axios from "axios";
import {STORAGE} from "../../app/config";
import {getFormData} from "../../utils";
import {Button, ClickAwayListener, Tooltip} from "@mui/material";
import ConfirmModal from "../modals/ConfirmModal";
import {LAYOUT_CONFIG} from "../../layoutConfig";

//#endregion

function ComplainMessage({data: item, onDelete}) {
    const [data, setData] = useState(item);
    const isAvatarSet = data.reporter?.avatar?.split('/')?.filter((s) => !!s)?.length > 1 ?? false;
    const {t} = useTranslation('ComplainDetailsScreen', {keyPrefix: 'Messages'});
    const {t: tMessage} = useTranslation('Modals', {keyPrefix: 'Messages'});
    const [isEditMode, setIsEditMode] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const handleCloseConfirmationModal = () => {
        confirmDelete();
        setOpenConfirmModal(false);
    }

    const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    
    const confirmEdit = (message, attachments) => {
        const attachmentsIds = attachments.map(a => a.id);

        try {
            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            axios.put(`${APP_URLS.EDIT_MESSAGE}/${item.id}`,
                {Content: message, Attachments: attachmentsIds},
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                }).then(response => {
                setData({...item, message, attachments});
            })
        } catch (error) {
            console.log('error :>> ', error);
        }
        
    }


    const confirmDelete = () => {
        try {
            const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
            axios.delete(`${APP_URLS.EDIT_DELETE}/${item.id}`,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }
                }).then(response => {
                onDelete(item.id);
            })
        } catch (error) {
            console.log('error :>> ', error);
        }
    }
    

    return (
        <div className={`fk-complain-message ${data.reporter ? '--reporter' : data.company ? '--company' : '--admin'}`}>
            <div className="fk-complain-message--header" style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                flexDirection: 'column'
            }}>
                <div>
                <span className="fk-complain-message--created-at">
                    {format(new Date(data.timestamp), "yyyy-MM-dd 'hour:' HH:mm")}
                </span>
                    {data.reporter &&
                        <div className="fk-complain-message--header-addressat --creator">
                            {t('from')}
                            <div className="fk-complain-message--header-addressat-name">
                                <div className="avatar-container">
                                    <img src={isAvatarSet ? `${APP_URLS.BASE}${data.reporter?.avatar}` : NoProfilePhoto}
                                         alt=""/>
                                </div>
                                {data.reporter?.initials}
                                <ConsumerRankIcon rank={data.reporter?.localizedRank} width={26} height={26}
                                                  className="rank-image"/>
                            </div>
                        </div>
                    }
                    {data.company &&
                        <div className="fk-complain-message--header-addressat">
                            {t('from')}
                            <div className="fk-complain-message--header-addressat-name">
                                {data.company?.name}
                            </div>
                        </div>
                    }
                    {data.admin &&
                        <div className="fk-complain-message--header-addressat">
                            {t('from')}
                            <div className="fk-complain-message--header-addressat-name">
                                {LAYOUT_CONFIG.ADMIN_NAME}
                            </div>
                        </div>
                    }
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button sx={{color: '#41A046', borderRight: 'solid 1px #41A046', borderBottomRightRadius: 0, borderTopRightRadius: 0}} onClick={() => setIsEditMode(true)}>{t('Edit')}</Button>
                    <Button sx={{color: '#41A046', borderBottomLeftRadius: 0, borderTopLeftRadius: 0}} onClick={() => setOpenConfirmModal(true)}>Delete</Button>
                </div>
            </div>
            {isEditMode ?
                <ComplaintMessageEditForm message={data} onConfirm={confirmEdit} onCancel={() => setIsEditMode(false)}/>
                : <div className={`fk-complain-message--body${data.admin ? ' --admin' : ''}`}>
                    <p className="fk-complain-message--description">
                        {data.message}
                    </p>
                    {data.attachments.length > 0 &&
                        <ul className="fk-files-list">
                            <li>
                                {t('attachments')}
                                <a href={`${serverUrl}/api/Report/${data.id}/downloadAllMessageAttachments`}
                                   target="_blank" rel="noreferrer">[Download all attachments]</a>
                            </li>
                            {data.attachments.map((file) => (
                                <li key={file.fileName}>
                                    {file.fileName}
                                    <a href={`${serverUrl}${file.path}`} target="_blank" rel="noreferrer">[Download attachment]</a>
                                </li>
                            ))}
                        </ul>
                    }
                </div>}
            <ConfirmModal title={tMessage('ConfirmDelete')} open={openConfirmModal} onClose={handleCloseConfirmationModal} />
        </div>
    );
}

export default ComplainMessage;