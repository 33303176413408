import SectorItem from "./components/SectorItem";
import {MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import {APP_URLS, companySectors, STORAGE} from "../../app/config";
import axios from "axios";
import {format} from "date-fns";
import {FilterMenu} from "../filterMenu";
import {useTranslation} from "react-i18next";
import CompaniesCard from "./companiesCard";
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import useSectors from "../../hooks/useSectors";
const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY));
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token?.token,
};


const SectorsTab = () => {
    const { sectors } = useSectors();
    const [selectedType, setSelectedType] = useState(0);
    const [complainTypes, setComplainTypes] = useState([])
    const [filter, setFilter] = useState({from: null, to: null})
    const {t} = useTranslation('ComplainsScreen', {keyPrefix: 'TableFilter'});

    useEffect(() => {
        if(sectors?.length > 0) {
            setSelectedType(sectors[sectors.length - 1].id);
        }
    }, [sectors]);

    const handleChange = (event) => {
        setSelectedType(event.target.value);
    };
    

    useEffect(() => {
        if(selectedType === 0) {
            return;
        }
        
        axios.get(`${APP_URLS.SECTOR_STATS}/${selectedType}`, {params: {...filter}}).then(response => {
            setComplainTypes(response.data);
        })
    }, [selectedType, filter.from, filter.to]);

    const handleFilterChange = (field, value) => {
        setFilter(prev => {
            return {
                ...prev,
                [field]: value,
            }
        })
    }

    const exportToCsv = () => {
        if(selectedType === 0) {
            return;
        }
        
        const dates = {
            from: filter.from ? filter.from?.toISOString() : '',
            to: filter.to ? filter.to?.toISOString() : '',
        }

        let params = new URLSearchParams(dates);
        let keysForDel = [];

        params.forEach((value, key) => {
            if (value === '') {
                keysForDel.push(key);
            }
        });

        keysForDel.forEach(key => {
            params.delete(key);
        });


        const url = `${APP_URLS.SECTOR_STATS}/${selectedType}/csv?${params.toString()}`;
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
    }

    return (
        <div className="rowContainer">
            <div className="bigCard">
                <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex', alignItems: 'center'}}>
                    <span className="header">The main type of problem by type of activity</span> 
                    <SaveAltRoundedIcon style={{color: '#41A046'}} onClick={exportToCsv}/>
                </div>
                <div style={{display: 'flex'}}>
                    <span className="label">Dates:</span>
                    <FilterMenu
                        datepicker
                        options={[]}
                        selected={filter.from}
                        buttonText={`${t('createdAt.from')} ${filter.from ? format(new Date(filter.from), "yyyy-MM-dd") : ''}`}
                        onChange={(option) => handleFilterChange('from', option)}
                    />
                    <FilterMenu
                        datepicker
                        options={[]}
                        selected={filter.to}
                        buttonText={`${t('createdAt.to')} ${filter.to ? format(new Date(filter.to), "yyyy-MM-dd") : ''}`}
                        onChange={(option) => handleFilterChange('to', option)}
                    />
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span className="label" style={{textWrap: 'nowrap', minWidth: 140}}>Type of activity:</span>
                    <Select
                        labelId="typeOfActivity-select"
                        id="select-type"
                        value={selectedType}
                        onChange={handleChange}
                        fullWidth
                        size="small">
                        {/*<MenuItem value={0}>All types</MenuItem>*/}
                        {sectors.map(({name, id}) => {
                            return <MenuItem value={id}>{name}</MenuItem>
                        })}
                    </Select>
                </div>
                <div className="itemsContainer">
                <SectorItem label={'Type of problem'} value={'Cases'} transparent={true}/>
                {complainTypes.map(({name, count}) => {
                    return <SectorItem label={name} value={count} />
                })}
                </div>
            </div>
            <CompaniesCard />
        </div>
    )
}

export default SectorsTab;