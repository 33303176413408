import { connect, useDispatch } from "react-redux";
import { complainStatuses, visibilityStates } from "../../../app/config";
import { /*fetchComplains,*/ fetchComplains, setFilter, unmergeComplains } from "../../../app/slices/complainsSlice";
import { FilterMenu } from "../../filterMenu";
import { ScreenHeader } from "../../screenHeader";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { fetchComplainTypes } from "../../../app/slices/complainTypesSlice";
import classNames from "classnames";
import { format } from "date-fns";
import { showModal } from "../../../app/slices/modalSlice";

function ComplainDuplicatesTableFilter({ parentId, selectedItems, filter, hideSearch, hideTitle, listTitle, csvLink }) {
  const dispatch = useDispatch();
  const { t } = useTranslation('ComplainsScreen', { keyPrefix: 'TableFilter' });
  const { t: t_common } = useTranslation('Common');
  let timer = null;

  useEffect(() => {
      dispatch(setFilter({ field: 'type', value: null }))
      dispatch(fetchComplainTypes(filter.sector?.value ?? -1));
  }, [filter.sector, dispatch]);

  let handleFilterChange = (field, value) => {
    clearTimeout(timer);

    dispatch(setFilter({ field, value, }));

    timer = setTimeout(() => {
      dispatch(fetchComplains({ parentId }));
    }, 500);
  };

  const handleUnmergeComplains = async () => {
    if (!selectedItems || selectedItems.length === 0) {
      dispatch(showModal({
        title: 'Nie zaznaczono skarg do odłączenia',
        message: 'Aby odłączyć skargi, należy wybrać z listy min. jedną pozycje.',
        mode: 'danger',
      }));

      return;
    }

    let children = selectedItems.map((complain) => complain.id);

    let result = await dispatch(unmergeComplains({
        childrenIds: children
    }));

    if (result.hasOwnProperty('error')) {
      dispatch(showModal({
        title: 'Wystąpił błąd podczas odłączania skarg.',
        mode: 'danger',
      }));
    } else {
      dispatch(showModal({
        title: 'Skargi zostały pomyślnie odłączone.',
        mode: 'normal',
      }));
      dispatch(fetchComplains({ parentId }));
    }
  }

  return (
    <>
        {!hideTitle &&
          <ScreenHeader withSearch={!hideSearch} search={filter.search} onChange={(event) => handleFilterChange('search', event.target.value)} />
        }
        <div className={
          classNames(
            'fk-filter-container',
            {
              '--only-filter': !listTitle,
            }
          )
        }>
          <h3>{listTitle}</h3>
          <div className="fk-filter">
          <FilterMenu
                  label={t('status.text')}
                  options={complainStatuses}
                  selected={filter.status}
                  onChange={(option) => handleFilterChange('status', option)}
                  tFunc={t}
                  tPrefix="status."
              />
              <FilterMenu
                  label={t('visibility.text')}
                  options={visibilityStates}
                  selected={filter.isPublic}
                  onChange={(option) => handleFilterChange('isPublic', option)}
                  tFunc={t}
                  tPrefix="visibility."
              />
              <FilterMenu
                  datepicker
                  label={t('createdAt.text')}
                  options={[]}
                  selected={filter.dateFrom}
                  buttonText={`${t('createdAt.from')} ${filter.dateFrom ? format(new Date(filter.dateFrom), "yyyy-MM-dd") : ''}`}
                  onChange={(option) => handleFilterChange('dateFrom', option)}
              />
              <FilterMenu
                  datepicker
                  options={[]}
                  selected={filter.dateTo}
                  buttonText={`${t('createdAt.to')} ${filter.dateTo ? format(new Date(filter.dateTo), "yyyy-MM-dd") : ''}`}
                  onChange={(option) => handleFilterChange('dateTo', option)}
              />
              {/*<FilterMenu*/}
              {/*    actionButton*/}
              {/*    buttonText={t('disconnectFromThisComplain')}*/}
              {/*    onClick={handleUnmergeComplains}*/}
              {/*/>*/}
              <FilterMenu
                  actionButton
                  link={csvLink}
                  buttonText={t_common('downloadCsv')}
              />
          </div>
        </div>
    </>
  );
}

const mapStateToProps = state => ({
  filter: state.complains.filter,
  complainTypes: state.complainTypes.data,
  selectedItems: state.complains.selectedItems,
  csvLink: state.complains.csvLink,
});

export default connect(mapStateToProps, {})(ComplainDuplicatesTableFilter);