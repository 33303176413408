import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import React from "react";
import { yesNoStatuses } from "../../../app/config";
import { FilterMenu } from "../../filterMenu";
import _ from "lodash";

function CompanyScoreSection({ model, onUpdate }) {
    const { t } = useTranslation('CompanyCreateScreen', { keyPrefix: 'ScoreSection' });
    const { t: t_common } = useTranslation('Common');

    return (
        <div className="fk-company-score-container">
            <div className="fk-company-score-visibility">
                <FilterMenu
                    label={t('scoreVisibilitySelectLabel')}
                    options={yesNoStatuses}
                    selected={_.find(yesNoStatuses, (status) => status.value === !!model.scoreVisible)}
                    tFunc={t_common}
                    buttonText={t_common(_.find(yesNoStatuses, (status) => status.value === !!model.scoreVisible)?.text)}
                    onChange={async (option) => await onUpdate('ScoreVisible', option.value)}
                />
            </div>
            <div className="fk-company-score">
                <div className="fk-company-score-points">
                    <span className="points-amount">{ (model.totalCompanyPoints?.totalPoints ?? 0) * 100  }</span>
                    {t('points')}
                    <div className="Stars" style={{'--rating': model.totalCompanyPoints?.totalPoints ?? 0}} />
                </div>
                <div className="fk-company-stats">
                    <p><span>{model.allReportsAmount}</span> {t('reportedComplainsAmount')}</p>
                    <p><span>{model.allClosedReportsAmount}</span> {t('closedComplainsAmount')}</p>
                    <p><span>{model.allAverageResponseTime}</span> {t('averageResponseTimeInDays')}</p>
                </div>
            </div>
            <span className="score-information">
                {t('howDoWeCountPoints')} <br />
                {t('theScoringMethodIsDescribedIn')} <a href={t('scoringMethodRulesLink')}>{t('regulations')}</a>
            </span>
            <div className="fk-company-score-visibility-buttons">
                <button
                    disabled={!!model.scoreVisible === true}
                    onClick={async (option) => await onUpdate('ScoreVisible', true)}
                >
                    {t('showCompanyScoreButton')}
                </button>
                <button
                    disabled={!!model.scoreVisible === false}
                    onClick={async (option) => await onUpdate('ScoreVisible', false)}
                >
                    {t('hideCompanyScoreButton')}
                </button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    model: state.companies.company
});

const actions = {
};

export default connect(mapStateToProps, actions)(CompanyScoreSection);