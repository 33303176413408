import {
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import React, { Component } from "react";
import { ConsumersTableFilter } from "../components";
import {
  fetchConsumers,
  previousPage,
  nextPage,
  resetFilter,
  setFilter,
  deleteConsumer,
  changeStatusConsumer,
} from "../app/slices/consumersSlice";
import { ConsumersTableRow, TableHeader, TablePagination } from "../components/table";
import { consumersTableHead } from "../app/config";
import "./screens.styles.scss";
import "../components/table/table.styles.scss";
import { connect } from "react-redux";
import {showModal} from "../app/slices/modalSlice";

class ConsumersScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: null,
    };

    this.props.resetFilter();
    this.refresh();

    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
  }

  refresh() {
    this.props.fetchConsumers(true);
  }

  handlePreviousPage() {
    this.props.previousPage();
    this.refresh();
  }

  handleNextPage() {
    this.props.nextPage();
    this.refresh();
  }

  handleRequestSort(event, property) {
    const direction =
      this.props.filter.orderBy === property &&
      this.props.filter.orderDirection === "asc"
        ? "desc"
        : "asc";
    this.props.setFilter({ field: "orderBy", value: property });
    this.props.setFilter({ field: "orderDirection", value: direction });

    this.refresh();
  }

  handleRowClick(id) {
    this.setState({
      open: this.state.open === id ? null : id,
    });
  }

  handleStatusChange(consumer) {
    const {id, isBlocked, fullName} = consumer;

    const content = {
      title: isBlocked ? 'Unblock Account' : 'Block Account',
      message: isBlocked ? 'Are you sure you want to unblock the consumer account?'
          : 'Are you sure you want to block the consumer account?',
      button: isBlocked ? 'Unblock' : 'Block'
    }

    this.props.showModal({
      title: content.title,
      message: `${content.message} ${fullName}?`,
      button: content.button,
      mode: 'normal',
      cancelButton: true,
      fallback: () => {
        this.props.changeStatusConsumer(id).then(async () => {
          await this.refresh();
        })
      }
    });
  }

  handleDelete(consumer) {
    this.props.showModal({
      title: 'Consumer account deletion',
      message: `Are you sure that you want to delete ${consumer.fullName}?`,
      button: 'Delete',
      mode: 'normal',
      cancelButton: true,
      fallback: () => {
        this.props.deleteConsumer(consumer.id).then(async () => {
          await this.refresh();
        })
      }
    });
  }

  render() {
    return (
      <div className="fk-screen">
        <ConsumersTableFilter />
        <TableContainer>
          <Table size="medium" className="fk-table">
            <TableHeader
              crud
              items={consumersTableHead}
              orderBy={this.props.filter.orderBy}
              order={this.props.filter.orderDirection}
              onRequestSort={this.handleRequestSort}
              screen="ConsumersScreen"
            />

            <TableBody>
                {this.props.consumers.map((consumer) => (
                    <ConsumersTableRow
                        key={consumer.id}
                        consumer={consumer}
                        open={this.state.open === consumer.id}
                        onRowClick={this.handleRowClick}
                        onStatusChange={this.handleStatusChange}
                        onDelete={this.handleDelete}
                    />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          filter={this.props.filter}
          handlePreviousPage={this.handlePreviousPage}
          handleNextPage={this.handleNextPage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  consumers: state.consumers.data,
  filter: state.consumers.filter,
});

const actions = {
  fetchConsumers,
  previousPage,
  nextPage,
  resetFilter,
  setFilter,
  deleteConsumer,
  showModal,
  changeStatusConsumer,
};

export default connect(mapStateToProps, actions)(ConsumersScreen);
