import { format } from "date-fns";
import { userRoles } from "../../../app/config";
import { userStatuses } from "../../../app/config/userStatuses";

export const infoFieldsArray = [
    {
        name: 'id',
        hideOnCreate: true,
    },
    {
        name: 'createdAt',
        hideOnCreate: true,
        convert: (value) => format(
                new Date(value),
                "yyyy-MM-dd 'heure:' HH:mm"
            )
    },
    {
        name: 'isBlocked',
        items: userStatuses
    },
    {
        name: 'email',
        modelName: 'Email',
    },
    {
        name: 'firstname',
        modelName: 'Firstname',
    },
    {
        name: 'lastname',
        modelName: 'Lastname',
    },
    {
        name: 'role',
        modelName: 'Role',
        onlyForAdmin: true,
        items: userRoles
    },
];