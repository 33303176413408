import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    show: false,
    title: '',
    message: '',
    button: 'Done',
    mode: 'normal',
    cancelButton: false,
    yesNoButtons: false,
    fallback: () => {},
    fallbackYes: () => {},
    fallbackNo: () => {},
};

const slice = createSlice({
    name: 'modal',
    initialState: {...defaultState},
    reducers: {
        showModal: (state, { payload }) => {
            state.show = true;
            state.title = payload.title;
            state.message = payload.message;
            state.button = (payload.button) ? payload.button : state.button;
            state.mode = (payload.mode) ? payload.mode : state.mode;
            state.cancelButton = payload.cancelButton;
            state.yesNoButtons = payload.yesNoButtons;
            state.fallback = (payload.fallback) ? payload.fallback : state.fallback;
            state.fallbackYes = (payload.fallbackYes) ? payload.fallbackYes : state.fallbackYes;
            state.fallbackNo = (payload.fallbackNo) ? payload.fallbackNo : state.fallbackNo;
        },
        hideModal: (state) => {
            state.show = false;
            state.title = '';
            state.message = '';
            state.button = 'Done';
            state.cancelButton = false;
            state.yesNoButtons = false;
            state.mode = 'normal';
            state.fallback = () => {};
            state.fallbackYes = () => {};
            state.fallbackNo = () => {};
        },
    }
});

export const {
    showModal,
    hideModal,
} = slice.actions;

export default slice.reducer;