import { Grid, MenuItem, TextField } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { complainMinStatuses } from "../../../app/config/complainStatuses";

function ComplainStatusForm({ onUpdate, isEditRequested, model: item}) {
    const [model, setModel] = useState(item);
    const { t } = useTranslation('ComplainDetailsScreen', { keyPrefix: 'StatusForm' });
    const [editEnabled, setEditEnabled] = React.useState(false);
    const statusRef = useRef(null);

    const getStatus = useCallback(() => {
        if (!model)
            return 0;
        
        if (model.isClosedAutomatically) return 7;
        if (model.isDraft) return 3;
        if (model.status === 1 && model.isResolved) return 4;
        if (model.status === 1 && !model.isResolved) return 5;
        

        return 0;
    }, [model])
    const [status, setStatus] = useState(getStatus());

    const handleStatusUpdate = async () => {
        await handleUpdate('Status', status);
    }

    const handleUpdate = async (name, value) => {
        await onUpdate(name, value, true);

        setEditEnabled(false);
    }

    useEffect(() => {
        if (isEditRequested) {
            setEditEnabled(true);
            statusRef.current.focus();
        }
    }, [isEditRequested]);

    // useEffect(() => {
    //     setStatus(getStatus());
    // }, [model.status, getStatus])

    const setComplaintStatus = (value) => {
        setStatus(value);
        setModel(prev => ({...prev, isClosedAutomatically: false}))
    }

    return (
        <div className="fk-form-section">
            <Grid container spacing={2}>
                <Grid item md={3} className="fk-label">
                    <label>{t('status')}</label>
                </Grid>
                <Grid item md={8} className="fk-input-container">
                    <TextField
                        ref={statusRef}
                        fullWidth
                        select={!!complainMinStatuses}
                        disabled={!editEnabled}
                        className="fk-input"
                        value={status ?? ''}
                        onChange={(event) => setComplaintStatus(event?.target?.value)}
                    >
                        {complainMinStatuses && complainMinStatuses.map(({value, text, disabled}) => (
                            <MenuItem key={value} value={value} disabled={disabled}>
                                {t(text)}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item md={1}>
                    {!editEnabled
                        ? <button
                            className="fk-edit-button"
                            onClick={() => setEditEnabled(true)}
                        >
                            <EditIcon />
                        </button>
                        : <button
                            className="fk-edit-button"
                            onClick={() => handleStatusUpdate()}
                        >
                            <SaveIcon />
                        </button>
                    }
                </Grid>
                {model.status === 1 &&
                    <>
                        <Grid item md={3} className="fk-label">
                            <label>{t('isResolved')}</label>
                        </Grid>
                        <Grid item md={9} style={{display: 'flex', alignItems: 'flex-end', lineHeight: '17px'}}>
                            {model.isResolved ? t('yes') : t('no')}
                        </Grid>
                    </>
                }
                {model.consumerComment &&
                    <>
                        <Grid item md={3} className="fk-label">
                            <label>{t('consumerComment')}</label>
                        </Grid>
                        <Grid item md={9} style={{display: 'flex', alignItems: 'flex-end', lineHeight: '17px'}}>
                            {model.consumerComment}
                        </Grid>
                    </>
                }

            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    model: state.complains.complain,
});

const actions = {
};

export default connect(mapStateToProps, actions)(ComplainStatusForm); 